<template>
  <v-container>
    <v-card outlined class="mt-2">
      <v-card-title> Оценка ЭП </v-card-title>
      <v-card-text v-if="assessment.economic.length">
        <CompareRow tag="economic" v-slot="{ value }">
          <v-data-table
            :items="value"
            :headers="assessmentHeaders"
            show-expand
            single-select
            single-expand
            item-key="id"
            :expanded.sync="expandedAssessments"
            disable-pagination
            hide-default-footer
            no-data-text="Информация о assessmentsData отсутствует"
          >
            <template #[`item.id`]="{ item }">
              <span class="text--disabled">{{ item.id }}.</span>
            </template>
            <template #[`item.condition`]="{ value }">
              <span v-if="value" class="font-weight-medium">{{ value }}</span>
              <span v-else class="warning--text"> ( пусто ) </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="td-expanded pa-2">
                <compare-economic-editor
                  :value="item"
                  @change="
                    item => {
                      const idx = value.findIndex(el => el.id === item.id)
                      value[idx] = item
                    }
                  "
                  @delete="
                    () => {
                      const idx = value.findIndex(el => el.id === item.id)
                      value.splice(idx - 1, 1)
                    }
                  "
                  @blur="doBlur"
                />
              </td>
            </template>
            <template
              v-slot:[`item.data-table-expand`]="{ expand, isExpanded }"
            >
              <td class="text-right">
                <v-btn
                  icon
                  @click="expand(!isExpanded)"
                  class="v-data-table__expand-icon"
                  :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                >
                  <v-icon>mdi-pencil-circle-outline</v-icon>
                </v-btn>
              </td>
            </template>
            <template #footer>
              <div class="d-flex pt-3">
                <div class="text-body-1">
                  <span>Максимальное значение: </span>
                  <span class="font-weight-bold">{{ maxValue }}</span>
                </div>
                <v-spacer />
                <v-btn
                  v-if="0"
                  color="accent"
                  fab
                  small
                  @click="
                    () => {
                      value.push({
                        ...ASSESSMENT_ORGANIZATION_DEFAULT(),
                        id: value.length + 1,
                      })
                      expandAdd(value)
                    }
                  "
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </CompareRow>
        <CompareRow tag="comment" v-slot="{ value, change }" class="d-flex">
          <v-text-field
            label="комментарий"
            :value="value"
            @change="change"
            hide-details
            class="my-4"
          />
        </CompareRow>
        <HelpCard
          caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ – ШКАЛЫ КЭИ и АВБ"
          outlined
          border
          class="mt-4"
        >
          <KeiScore class="mb-4" />
          <AvbScore />
        </HelpCard>
        <HelpCard
          caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ – баллы для оценки экономических последствий"
          outlined
          border
          class="mt-4"
        >
          <RecommendedEPScore />
        </HelpCard>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CompareRow from '@/components/compare/CompareRow'
import { ASSESSMENT_ORGANIZATION_DEFAULT } from './const'
import { DEFAULT_ECONOMIC } from '@/components/expSummary/summaryScore/assessmentConst'
import CompareEconomicEditor from './CompareEconomicEditor.vue'
import HelpCard from '@/components/HelpCard'
import KeiScore from '@/components/expSummary/scales/KeiScore'
import AvbScore from '@/components/expSummary/scales/AvbScore'
import RecommendedEPScore from '../expSummary/scales/RecommendedEPScore.vue'

export default {
  name: 'CompareAssessment',
  components: {
    CompareRow,
    CompareEconomicEditor,
    HelpCard,
    KeiScore,
    AvbScore,
    RecommendedEPScore,
  },
  computed: {
    ...mapGetters(['plp_task_id', 'compare_data']),
    maxValue() {
      return this.getMaxValue()
    },
  },
  data() {
    return {
      ASSESSMENT_ORGANIZATION_DEFAULT,
      assessment: ASSESSMENT_ORGANIZATION_DEFAULT(),
      expandedAssessments: [0],
      economic: [],
      assessmentHeaders: [
        {
          text: '№',
          value: 'id',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Показание',
          value: 'condition',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  mounted() {
    this.economic = this.assessment?.economic || []
  },
  methods: {
    ...mapMutations(['COMPARE_DATA', 'COMPARE_PART_DATA']),
    expand(item = null) {
      this.expandedAssessments = item ? [item] : []
    },
    deleteAssessments(idx) {
      this.economic.splice(idx - 1, 1)
      this.$set(this, 'economic', this.economic)
      this.expand(null)
    },
    addAssessment() {
      this.economic.push(DEFAULT_ECONOMIC())

      this.$nextTick(() => {
        const lg = this.economic?.length || 0
        this.expand(this.economic[lg - 1])
      })
    },
    expandAdd(value) {
      this.$nextTick(() => {
        const lg = value?.length || 0
        this.expand(value[lg - 1])
      })
    },
    doChangeItem(item) {
      const idx = item.idx - 1
      this.economic[idx] = item
    },
    doBlur() {
      this.$emit('blur')
    },
    getMaxValue() {
      if (!this.economic?.length) return 0
      return Math.max(...this.economic.map(item => item.resultScore)) || 0
    },
  },
}
</script>
