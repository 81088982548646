<template>
  <v-container>
    <v-card outlined class="mt-2" v-for="(el, index) in other" :key="el.id">
      <v-card-title>{{ index + 1 }}. {{ el.text }}</v-card-title>
      <v-card-subtitle class="red-line"
        >{{ el.commentary | capitalize }}
        <span class="font-italic text--disabled cursor-help"
          >(
          {{ el.weight | ru_ending(['балл', 'балла', 'баллов']) }}
          )</span
        >
      </v-card-subtitle>
      <v-card-text class="pb-0"
        ><CompareRow
          root="other"
          :tag="index.toString()"
          v-slot="{ value, change }"
        >
          <v-checkbox
            v-if="value"
            :input-value="value.state"
            class="my-0 align-self-center"
            :title="`Добавляет баллов: ${el.weight}`"
            @change="state => calcResult(change, value, state, index)"
          ></v-checkbox> </CompareRow
      ></v-card-text>
      <v-card-text v-if="el.text === 'Преобладание заболеваемость'">
        <CompareRow tag="comment" v-slot="{ value, change }" class="d-flex">
          <v-text-field
            label="комментарий"
            :value="value"
            @change="change"
            hide-details
            class="my-4"
          />
        </CompareRow>
      </v-card-text>
    </v-card>
    <v-card outlined class="mt-2">
      <v-card-title>СУММА БАЛЛОВ</v-card-title>
      <v-card-text class="pb-0">
        <CompareRow v-slot="{ value, change, data }" hide-use-btn>
          <!--FIXME: Остался вот этот прекрасный костыль (в моментт рендера обновляем сумму) -->
          <v-chip
            v-if="value && changeSum(data, change)"
            class="text-h5 px-5 text--primary align-self-center"
          >
            {{ value.sum }}
          </v-chip>
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- справочная информация -->
    <HelpCard class="mt-2" outlined open>
      <!--вызов списка файлов из п.5.1 эпид данные-->
      <PlpFilesList plp-key="epidData" text="5.1 Эпидемиологические данные" />
    </HelpCard>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CompareRow from '@/components/compare/CompareRow'
import PlpFilesList from '@/components/plp/PlpFilesList'
import HelpCard from '@/components/HelpCard'
import { OTHER_MEDICINE_EXP, resetStates } from './const'
import { copyObject } from '@/lib/objects'
export default {
  name: 'CompareOther',
  components: { CompareRow, PlpFilesList, HelpCard },
  computed: {
    ...mapGetters(['plp_task_id', 'compare_data']),
  },
  data() {
    return {
      other: OTHER_MEDICINE_EXP,
      options: {},
    }
  },
  created() {
    OTHER_MEDICINE_EXP.forEach(({ id, group, commentary }) => {
      this.options[id] = { group, commentary }
    })
  },
  methods: {
    ...mapMutations(['COMPARE_DATA', 'COMPARE_PART_DATA']),
    changeSum(data, change) {
      if (!data) return data
      let sum = 0
      data.other.forEach(el => {
        sum += el.state ? el.weight : 0
      })
      change({ ...data, sum })
      return true
    },
    calcResult(change, value, state, index) {
      change({ ...value, state })
      let changed_data = copyObject(this.compare_data)
      resetStates(state, index, changed_data, this.options)
      this.COMPARE_DATA(changed_data)
    },
  },
}
</script>
