var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Оценка ЭП ")]),(_vm.assessment.economic.length)?_c('v-card-text',[_c('CompareRow',{attrs:{"tag":"economic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('v-data-table',{attrs:{"items":value,"headers":_vm.assessmentHeaders,"show-expand":"","single-select":"","single-expand":"","item-key":"id","expanded":_vm.expandedAssessments,"disable-pagination":"","hide-default-footer":"","no-data-text":"Информация о assessmentsData отсутствует"},on:{"update:expanded":function($event){_vm.expandedAssessments=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(item.id)+".")])]}},{key:"item.condition",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"td-expanded pa-2",attrs:{"colspan":headers.length}},[_c('compare-economic-editor',{attrs:{"value":item},on:{"change":function (item) {
                    var idx = value.findIndex(function (el) { return el.id === item.id; })
                    value[idx] = item
                  },"delete":function () {
                    var idx = value.findIndex(function (el) { return el.id === item.id; })
                    value.splice(idx - 1, 1)
                  },"blur":_vm.doBlur}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
                  var expand = ref.expand;
                  var isExpanded = ref.isExpanded;
return [_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('div',{staticClass:"text-body-1"},[_c('span',[_vm._v("Максимальное значение: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.maxValue))])]),_c('v-spacer'),(0)?_c('v-btn',{attrs:{"color":"accent","fab":"","small":""},on:{"click":function () {
                    value.push(Object.assign({}, _vm.ASSESSMENT_ORGANIZATION_DEFAULT(),
                      {id: value.length + 1}))
                    _vm.expandAdd(value)
                  }}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()],1)]},proxy:true}],null,true)})]}}],null,false,2977843777)}),_c('CompareRow',{staticClass:"d-flex",attrs:{"tag":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{staticClass:"my-4",attrs:{"label":"комментарий","value":value,"hide-details":""},on:{"change":change}})]}}],null,false,305610109)}),_c('HelpCard',{staticClass:"mt-4",attrs:{"caption":"СПРАВОЧНАЯ ИНФОРМАЦИЯ – ШКАЛЫ КЭИ и АВБ","outlined":"","border":""}},[_c('KeiScore',{staticClass:"mb-4"}),_c('AvbScore')],1),_c('HelpCard',{staticClass:"mt-4",attrs:{"caption":"СПРАВОЧНАЯ ИНФОРМАЦИЯ – баллы для оценки экономических последствий","outlined":"","border":""}},[_c('RecommendedEPScore')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }